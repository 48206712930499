import React, { useEffect, useContext, useState,useRef } from "react";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { NavLink } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getCookie,
  modalLoading,
  pageLoading,
  paginationLoading,
  paginationOrderHistory,
  showingDataOrderHistory,
  searchedShowingDataOrderHistory,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";

//importing context consumer here
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { FoodContext } from "../../../../contexts/Food";

export default function WalletTopupTransactions() {
    const {
        //wallet
        walletTopTransactionLog,
        getWalletTopupTransactionLog,
        loading,
        setLoading

      } = useContext(RestaurantContext);
      const { t } = useTranslation();


      useEffect(()=>{
getWalletTopupTransactionLog();
      },[])

  const getResponse=(res)=>{
const jsonObject = JSON.parse(res)=="{}"?null:res;
let jsontwo = JSON.parse(jsonObject)

return jsontwo;

  }

  return (
    <>
    <Helmet>
      <title>{_t(t("Wallet Topup Log"))}</title>
    </Helmet>
  

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          <div className="col-12 t-mb-30 mb-lg-0">
            {loading === true ? (
              pageLoading()
            ) : (
              <div className="t-bg-white ">
                {/* next page data spin loading */}
                <div className={`${loading && "loading"}`}></div>
                {/* spin loading ends */}
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                  <div className="col-12 t-mb-15">
                    <ul className="t-list fk-breadcrumb">
                      <li className="fk-breadcrumb__list">
                        <span className="t-link fk-breadcrumb__link text-uppercase">
                        
                            { _t(t("Wallet Topup Log"))}
                           
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-4 col-lg-3">
                    <div className="input-group">
                      <button className="btn btn-primary" type="button">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <div className="form-file">
                        <input
                          type="text"
                          className="form-control border-0 form-control--light-1 rounded-0"
                          placeholder={
                            _t(t("Search by token, customer, order id")) + ".."
                          }
                         // onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
    
                  <div className="col-md-8 col-lg-9 t-mb-15 mb-md-0 d-none d-md-block">
                      <ul className="t-list fk-sort align-items-center justify-content-end">
                      <li className="fk-sort__list ml-2">
                      <li class="fk-sort__list">
                          <NavLink
                            to="/dashboard/app-transactions"
                            class="btn btn-transparent btn-secondary xsm-text text-uppercase py-2"
                          >
                            {_t(t("APP TRANSACTIONS"))}
                          </NavLink>
                        </li>
                      
                      </li>
                      <li className="fk-sort__list ml-2">
                          <DatePicker
                          //  selected={startDate}
                          //  onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control xsm-text py-2"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={false}
                          />
                        </li>
                        <li className="fk-sort__list">
                          <span className="fk-sort__icon">
                            <span className="fa fa-long-arrow-right"></span>
                          </span>
                        </li>
                        <li className="fk-sort__list">
                          <DatePicker
                          //  selected={endDate}
                           // onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control xsm-text py-2"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={false}
                          />
                        </li>
                        <li class="fk-sort__list">
                          <button
                            class="btn btn-transparent btn-danger xsm-text text-uppercase py-2"
                            // onClick={
                            // //  handleDateFilter
                            // }
                          >
                            {_t(t("Filter"))}
                          </button>
                        </li>
                      </ul>
                    </div>

               
                </div>
                <div className="fk-scroll--order-history" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover min-table-height mt-4">
                        <thead className="align-middle">
                          <tr>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("OID"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("IPG"))}
                            </th>
        

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Date/Time"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("CID"))}
                            </th>
                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Customer"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Total bill"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Card"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Status"))}
                            </th>

                            <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Details"))}
                            </th>

                         
                          </tr>
                        </thead>
                        <tbody className="align-middle">
                      
                          {walletTopTransactionLog
                            ? [
                                walletTopTransactionLog && [
                                  walletTopTransactionLog.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="10"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    walletTopTransactionLog.map((item, index) => {
                                      return (
                                      
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          {/* <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index +
                                              1 +
                                              (walletTopTransactionLog.meta.current_page -
                                                1) *
                                                walletTopTransactionLog.meta.per_page}
                                          </th> */}
 
                                          <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                            {_t(t(getResponse(item.response).oid))}
                                          </td>

                                          <td className="xsm-text text-capitalize align-middle text-center">
                                          
                                          {_t(t(getResponse(item.response).ipgTransactionId))}
                                          
                                          </td>
                                        
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                          
                                          {_t(t(getResponse(item.response).txndatetime))}
                                          
                                          </td>
                                          <td className="xsm-text text-capitalize align-middle text-center">
                                          
                                          {_t(t(item.user_id)  || '-')}
                                          
                                          </td>
                                          <td className="xsm-text align-middle text-center">
                                            {getResponse(item.response).bname}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {currencySymbolLeft()}
                                            {formatPrice(getResponse(item.response).chargetotal)}
                                            {currencySymbolRight()}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                          {getResponse(item.response).cardnumber}
                                          </td>

                                          <td class="xsm-text text-capitalize align-middle text-center">
                                            {getResponse(item.response).status ===
                                            "APPROVED"? (
                                            
                                                  <span
                                                    class="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                                 
                                                  >
                                                    {_t(t("APPROVED"))}
                                                  </span>
                                              
                                            ) : getResponse(item.response).status ===
                                                "DECLINED"? (
                                              <span
                                                class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                          
                                              >
                                                {_t(t("DECLINED"))}
                                              </span>
                                            ): getResponse(item.response).status ===
                                            "FAILED"? 
                                            <span
                                            class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                           
                                          >
                                            {_t(t("FAILED"))}
                                          </span>:
                                             <span
                                             class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                       
                                           >
                                             {_t(t(getResponse(item.response).status))}
                                           </span>
                                            }
                                          </td>

                                          <td className="xsm-text align-middle text-left">
                                          {_t(t(getResponse(item.response).fail_reason??
                                           getResponse(item.response).approval_code
                                           ))}
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center">
                                            <div className="dropdown text-capitalize">
                                              <button
                                                className="btn t-bg-clear t-text-dark--light-40"
                                                type="button"
                                                data-toggle="dropdown"
                                              >
                                                <i className="fa fa-ellipsis-h"></i>
                                              </button>
                                              <div className="dropdown-menu">
                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() => {
                                                   
                                                  }}
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-trash"></i>
                                                  </span>
                                                  {_t(t("Delete Order"))}
                                                </button>
                                              </div>
                                            </div>
                                          </td> */}
                                        </tr>
                                      );
                                    })
                                  ),
                                ],
                              ]
                            : [
                                /* searched data, logic === haveData*/
                                // searchedOrders && [
                                //   searchedOrders.list.length === 0 ? (
                                //     <tr className="align-middle">
                                //       <td
                                //         scope="row"
                                //         colSpan="10"
                                //         className="xsm-text align-middle text-center"
                                //       >
                                //         {_t(t("No data available"))}
                                //       </td>
                                //     </tr>
                                //   ) : (
                                //     searchedOrders.list.map((item, index) => {
                                //       return (
                                //         <tr
                                //           className="align-middle"
                                //           key={index}
                                //         >
                                //           <th
                                //             scope="row"
                                //             className="xsm-text text-capitalize align-middle text-center"
                                //           >
                                //             {index +
                                //               1 +
                                //               (walletTopTransactionLog.meta.current_page -
                                //                 1) *
                                //                 walletTopTransactionLog.meta.per_page}
                                //           </th>

                                //           <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                //             #{item.token.id}
                                //           </td>

                                //           <td className="xsm-text text-capitalize align-middle text-center">
                                //             <Moment format="LT">
                                //               {item.token.time}
                                //             </Moment>
                                //           </td>

                                //           <td className="xsm-text text-capitalize align-middle text-center">
                                //             <Moment format="LL">
                                //               {item.created_at}
                                //             </Moment>
                                //           </td>

                                //           <td className="xsm-text align-middle text-center">
                                //             {item.customer_name}
                                //           </td>

                                //           <td className="xsm-text align-middle text-center">
                                //             {currencySymbolLeft()}
                                //             {formatPrice(item.total_payable)}
                                //             {currencySymbolRight()}
                                //           </td>

                                //           <td className="xsm-text align-middle text-center">
                                //             {item.branch_name || "-"}
                                //           </td>

                                //           <td class="xsm-text text-capitalize align-middle text-center">
                                //             {parseInt(item.is_cancelled) ===
                                //             0 ? (
                                //               [
                                //                 parseInt(item.is_ready) ===
                                //                 0 ? (
                                //                   <span
                                //                     class="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                //                     onClick={() => {
                                                     
                                //                     }}
                                //                     data-toggle="modal"
                                //                     data-target="#orderDetails"
                                //                   >
                                //                     {_t(t("processing"))}
                                //                   </span>
                                //                 ) : (
                                //                   <span
                                //                     class="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                //                     onClick={() => {
                                                     
                                //                     }}
                                //                     data-toggle="modal"
                                //                     data-target="#orderDetails"
                                //                   >
                                //                     {_t(t("Ready"))}
                                //                   </span>
                                //                 ),
                                //               ]
                                //             ) : (
                                //               <span
                                //                 class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                //                 onClick={() => {
                                                  
                                //                 }}
                                //                 data-toggle="modal"
                                //                 data-target="#orderDetails"
                                //               >
                                //                 {_t(t("Cancelled"))}
                                //               </span>
                                //             )}
                                //           </td>

                                //           <td className="xsm-text align-middle text-center">
                                //             <button
                                //               className="btn btn-secondary btn-sm"
                                //               onClick={() => {
                                            
                                //               }}
                                //             >
                                //               <i className="fa fa-print"></i>
                                //             </button>
                                //           </td>
                                //           <td className="xsm-text align-middle text-center">
                                //             <div className="dropdown text-capitalize">
                                //               <button
                                //                 className="btn t-bg-clear t-text-dark--light-40"
                                //                 type="button"
                                //                 data-toggle="dropdown"
                                //               >
                                //                 <i className="fa fa-ellipsis-h"></i>
                                //               </button>
                                //               <div className="dropdown-menu">
                                //                 <button
                                //                   className="dropdown-item sm-text text-capitalize"
                                //                   onClick={() => {
                                                  
                                //                   }}
                                //                 >
                                //                   <span className="t-mr-8">
                                //                     <i className="fa fa-trash"></i>
                                //                   </span>
                                //                   {_t(t("Delete Order"))}
                                //                 </button>
                                //               </div>
                                //             </div>
                                //           </td>
                                //         </tr>
                                //       );
                                //     })
                                //   ),
                                // ],
                              ]}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* pagination loading effect */}
            {/* {loading === true
              ? paginationLoading()
              : [
                  // logic === !searched
                  !searchedOrders.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function 
                            {paginationOrderHistory(
                              walletTopTransactionLog,
                              setPaginatedwalletTopTransactionLog
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingDataOrderHistory(walletTopTransactionLog)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                 
                                }}
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                fsdgsdg
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]} */}
          </div>
        </div>
      </div>
    </main>
  </>
  )
}

