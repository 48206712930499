import React from 'react';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const OrdersExcel = ({ orders }) => {


  return (
    <ExcelFile element={<button className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100">Export Excel</button>}>
      <ExcelSheet data={orders} name="Orders List"  filename="Orders List"  > 
        <ExcelColumn label="Order ID" value="id" />
        <ExcelColumn label="Token No" value="token" />
        <ExcelColumn label="Created Date" value="pre_order_date" />
        <ExcelColumn label="Pre Order Date" value="pre_order_date" />
        <ExcelColumn label="Branch Name" value="branch_name" />
        <ExcelColumn label="Bill Amount" value="total_payable" />
      
        <ExcelColumn label="Child Name" value={(col) => col.customer[0].name} />
        <ExcelColumn label="Grade" value={(col) => col.customer[0].grade} />
        <ExcelColumn label="Section" value={(col) => col.customer[0].section} />
        <ExcelColumn label="Allergies" value={(col) => col.customer[0].has_allergies==1?'Yes':'No'} />
        <ExcelColumn label="Allergy Details" value={(col) => col.customer[0].allergy_details} />
        <ExcelColumn label="Email" value={(col) => col.customer[0].email} />
        <ExcelColumn label="Parent Id" value="user_id" />
        <ExcelColumn label="Parent" value="user_name" />
      
        <ExcelColumn label="Item Count" value={(col) => col.orderedItems.length} />
        <ExcelColumn label="Item 1" value={(col) =>` ${col.orderedItems[0].food_group} - ${col.orderedItems[0].food_item } (${col.orderedItems[0].food_item_id})`} />
        <ExcelColumn label="Item 2" value={(col) =>col.orderedItems[1]?`${col.orderedItems[1].food_group} - ${col.orderedItems[1].food_item} (${col.orderedItems[1].food_item_id})`:''} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default OrdersExcel;