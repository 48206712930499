import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom"
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons, BraintreePayPalButtons } from "@paypal/react-paypal-js";

//importing context consumer here
import { UserContext } from "../../../contexts/User";
import { FoodContext } from "../../../contexts/Food";
import { RestaurantContext } from "../../../contexts/Restaurant";
import { SettingsContext } from "../../../contexts/Settings";

//axios and base url
import axios from "axios";
import { BASE_URL, SAAS_APPLICATION } from "../../../BaseUrl";

//functions
import {
  _t,
  modalLoading,
  restaurantMenuLink,
  getSystemSettings,
  getCookie,
  deleteCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

const TodayMenu = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  //getting context values here
  let { navLanguageList, navCurrencyList, generalSettings, showManageStock, paypal_client_id } =
    useContext(SettingsContext);
  //auth user
  const { authUserInfo } = useContext(UserContext);
  //restaurant
  let { branchForSearch } = useContext(RestaurantContext);
  //food
  let {
    getFoodWeb,
    foodListWeb,
    foodGroupWeb,
    propertyGroupWeb,
    workPeriodWeb,
    foodStockWeb,
    setFoodStockWeb,
  } = useContext(FoodContext);

  //use state
  const [loading, setLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showVariation, setShowVariation] = useState(false);

  const [deliverymenShow, setdeliverymenShow] = useState(false);
  const [checkLoginUser, setCheckLoginUser] = useState(0);
  //
  const [defaultLang, setDefaultLang] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  //food and group
  const [foodItems, setFoodItems] = useState({
    list: null,
    group: null,
    selectedItem: null,
    variations: null,
    properties: null,
  });

  //new order
  const [newOrder, setNewOrder] = useState({
    variation: null,
    quantity: 1,
    properties: null,
  });

  //order details
  const [orderDetails, setOrderDetails] = useState({
    items: [],
    branch: null,
    workPeriod: null,
    workPeriodStatus: false,
    address: null,//null
    name: null,
    phn_no: null,
    note: null,
    payment_type: "COD",
    uploading: false,
  });

  // check auth user 
  const checkLoginfunc = () => {
    getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
  }

  //useeffect == componentDidMount()
  useEffect(() => {
    handleJquery();
    getFoodWeb();
    deliveryMenu();
    handleOnLoadDefaultLang();
    handleOnLoadDefaultCurrency();
    checkLoginfunc();

    setOrderDetails({
      ...orderDetails,
      address: authUserInfo.details && authUserInfo.details.address,
      name: authUserInfo.details && authUserInfo.details.name,
      phn_no: authUserInfo.details && authUserInfo.details.phn_no,
    });
    if (foodGroupWeb && foodListWeb) {
      let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
        return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
      });
      setFoodItems({ ...foodItems, list: temp, group: foodGroupWeb[0] });
    }
    setTimeout(() => {
      setLoading(false);
    }, 2500);

  }, [authUserInfo, navCurrencyList,]);


  // deliveryman menu update
  const deliveryMenu = () => {
    const url = BASE_URL + `/settings/deliverymen-menu-info`;
    axios.get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
      if (res.data.length == 0 || res.data[0].value == 1) {
        setdeliverymenShow(true);
      } else {
        setdeliverymenShow(false);
      }
    });

  }

  //handle jQuery
  const handleJquery = () => {
    $(window).on("scroll", function () {
      var toTopVisible = $("html").scrollTop();
      if (toTopVisible > 500) {
        $(".scrollup").fadeIn();
      } else {
        $(".scrollup").fadeOut();
      }
    });

    // MouseHover Animation home 1
    var hoverLayer = $(".banner-area");
    var heroImgOne = $(".p-shape-1");
    var heroImgTwo = $(".p-shape-2");
    var heroImgThree = $(".p-shape-3");
    var heroImgFour = $(".p-shape-4");
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgOne.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgTwo.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgThree.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgFour.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 2
    var hoverLayer2 = $(".burger-promo-area");
    var heroImgfive = $(".bs1");
    var heroImgsix = $(".bs2");
    var heroImgseven = $(".bs5");
    var heroImgeight = $(".bs6");
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgfive.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgsix.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgseven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeight.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 3
    var hoverLayer3 = $(".snack-section");
    var heroImgnine = $(".ss1");
    var heroImgten = $(".ss2");
    var heroImgeleven = $(".ss3");
    var heroImgtweleve = $(".mss2");
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgtweleve.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgnine.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgten.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeleven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
  };

  //dynamic style
  const style = {
    logo: {
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };






  //stock
  const handleGetStock = (id) => {
    if (orderDetails.branch === null) {
      return 0;
    }
    let stock = foodStockWeb.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === parseInt(orderDetails.branch)
      );
    });
    if (stock === undefined || stock.qty < 0) {
      return 0;
    }
    return stock.qty;
  };

  


  //set default language on site load
  const handleOnLoadDefaultLang = () => {
    let localLang = localStorage.i18nextLng;
    if (localLang) {
      if (localLang === undefined || localLang.includes("en-")) {
        navLanguageList &&
          navLanguageList.map((item) => {
            if (item.is_default === true) {
              i18n.changeLanguage(item.code);
              setDefaultLang(item);
            }
            return true;
          });
      } else {
        const temp =
          navLanguageList &&
          navLanguageList.find((item) => {
            return item.code === localLang;
          });
        setDefaultLang(temp);
        i18n.changeLanguage(localLang);
      }
    }
  };


  //set default currency on site load
  const handleOnLoadDefaultCurrency = () => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if (localCurrency === null) {
      navCurrencyList &&
        navCurrencyList.map((item) => {
          if (item.is_default === true) {
            setDefaultCurrency(item);
            localStorage.setItem("currency", JSON.stringify(item));
          }
          return true;
        });
    } else {
      const temp =
        navCurrencyList &&
        navCurrencyList.find((item) => {
          return item.code === localCurrency.code;
        });
      setDefaultCurrency(temp);
    }
  };

  return (
    <>


      <Helmet>
        <title>
          {generalSettings && getSystemSettings(generalSettings, "siteName")}
        </title>
        <link rel="stylesheet" href="/website/css/animate.css" />
        <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
        <link rel="stylesheet" href="./website/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/website/css/font-awsome-all.min.css" />
        <link rel="stylesheet" href="/website/css/magnific-popup.css" />
        <link rel="stylesheet" href="/website/css/slick.css" />
        <link rel="stylesheet" href="/website/css/jquery-ui.css" />
        <link rel="stylesheet" href="/website/css/style.css" />

        <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>
        <script src="/website/js/vendor/bootstrap.min.js"></script>
        <script src="./website/js/vendor/jquery.meanmenu.min.js"></script>
        <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
        <script src="/website/js/vendor/slick.min.js"></script>
        <script src="/website/js/vendor/counterup.min.js"></script>
        <script src="/website/js/vendor/countdown.js"></script>
        <script src="/website/js/vendor/waypoints.min.js"></script>
        <script src="/website/js/vendor/jquery-ui.js"></script>
        <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
        <script src="/website/js/vendor/easing.min.js"></script>
        <script src="/website/js/vendor/wow.min.js"></script>
        <script src="/website/js/simplebar.js"></script>
        <script src="/website/js/main.js"></script>
      </Helmet>

      {/* <!-- Preloader Starts --> */}
      <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>

      {/* Variation and property drawer ends */}
      <div className={loading ? "d-none" : ""}>

        {/* <!-- menu-area --> */}
        <section
          className="menu-area home2-menu-area padding-top-0"
          id="popular"
        >
          <div className="menu-shapes">
            <span className="ds-1">
              <img src="/website/images/shapes/34.png" alt="" />
            </span>
            <span className="ds-2 item-bounce">
              <img src="/website/images/shapes/sm-tomatto.png" alt="" />
            </span>
            <span className="ds-3">
              <img src="/website/images/shapes/donar.png" alt="" />
            </span>
            <span className="ds-4 item-bounce">
              <img src="/website/images/shapes/triple.png" alt="" />
            </span>
            <span className="ds-5">
              <img src="/website/images/shapes/scatter.png" alt="" />
            </span>
          </div>

          <div className="container">
            <div className="common-title-area padding-50 wow fadeInLeft">
              <h3>{_t(t("Today"))}</h3>
              <h2>
                {_t(t("Special"))} <span>{_t(t("menu"))}</span>{" "}
              </h2>
          
            {/* <!-- menu-nav-wrapper --> */}
            <div className="menu-nav-wrapper">
              <div className="container">
                <div className="row">
                  <nav>
                    <div className="nav justify-content-center custom-nav">
                      {/* Food group */}
                      {foodGroupWeb &&
                        foodGroupWeb.map((groupItem, groupItemIndex) => {
                          return (
                            <a
                              href="#menus"
                              className={`nav-item nav-link ${foodItems.group &&
                                foodItems.group.id === groupItem.id
                                ? "active"
                                : ""
                                }`}
                              onClick={() => {
                                if (foodGroupWeb && foodListWeb) {
                                  let temp = foodListWeb.filter(
                                    (foodItem, foodItemIndex) => {
                                      return (
                                        parseInt(foodItem.food_group_id) ===
                                        groupItem.id
                                      );
                                    }
                                  );
                                  setFoodItems({
                                    ...foodItems,
                                    list: temp,
                                    group: groupItem,
                                  });
                                }
                              }}
                            >
                              <div style={{height:'20px',alignContent:'center'}} className="single-menu-nav text-center">
                                <h6>{groupItem.name}</h6>
                                {/* <span className="g-s-4">
                                  <img
                                    src="/website/images/shapes/10.png"
                                    alt=""
                                  />
                                </span>
                                <span className="g-s-5">
                                  <img
                                    src="/website/images/shapes/14.png"
                                    alt=""
                                  />
                                </span> */}
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            {/* <!-- menu-items-wrapper --> */}
            </div>
            <div className="tab-content" id="nav-tabContent">
              {/* <!-- menu-items --> */}
              <div>
                <div className="menu-items-wrapper">
                  <div className="menu-i-shapes">
                    <span className="mis-3">
                      <img src="/website/images/shapes/7.png" alt="" />
                    </span>
                  </div>
                  <div className="row" id="menus">
                    {foodItems.list &&
                      foodItems.list.map((foodItem, foodItemIndex) => {
                        return (
                          <div className="col-lg-4 col-md-4 t-mt-10" key={foodItemIndex}>
                            <div style={{minHeight:100}} className="single-menu-item d-flex align-items-center h-100">
                              <div  className="w-150">
                                <img style={{width:'200px', height:'200px'}} src={foodItem.image} alt="" />
                                <div>
                                  <small style={{background:'#00b07b',color:'white', padding:5,paddingLeft:10,paddingRight:10, borderRadius:20}}>
                                    {foodItem.nutrition} kcal
                                  </small>
                                  </div>
                              </div>
                              <div className="menu-content">
                                <h6>
                                  <a
                                    className="pointer-cursor"
                                    onClick={() => {
                                      if (checkLoginUser === 0) {
                                        setFoodItems({
                                          ...foodItems,
                                          selectedItem: foodItem,
                                          variations:
                                            parseInt(foodItem.has_variation) === 1
                                              ? foodItem.variations
                                              : null,
                                          properties:
                                            parseInt(foodItem.has_property) === 1
                                              ? foodItem.properties
                                              : null,
                                        });
                                        setNewOrder({
                                          variation: null,
                                          quantity: 1,
                                          properties: null,
                                        });
                                        setShowVariation(true);
                                      } else {
                                        toast.error(`${_t(t("Please login first"))}`, {
                                          position: "bottom-center",
                                          autoClose: 10000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          className: "text-center toast-notification",
                                        });
                                      }

                                    }}
                                  >
                                    {foodItem.name}
                                  </a>
                                </h6>
                                <p>

                                <small >
                                {foodItem.description}
                                  </small>

                                  {/* <small style={{background:'#00b07b',color:'white', padding:5, borderRadius:20}}>
                                    {showManageStock ? ([_t(t("Available"))]) : null}
                                  </small> */}
                                </p>
                                <span>
                                  {parseInt(foodItem.has_variation) !== 1 &&
                                    currencySymbolLeft() +
                                    formatPrice(foodItem.price) +
                                    currencySymbolRight()}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- drink-items section --> */}
        <section
          className="drink-items-section  padding-top-110"
          id="special"
        >
          <div className="drink-items-shapes">
            <span className="fs-1 item-animateOne">
              <img src="/website/images/img/htshape2.png" alt="" />
            </span>
            <span className="fs-2">
              <img src="/website/images/img/htshape16.png" alt="" />
            </span>
            <span className="fs-3 item-animateTwo">
              <img src="/website/images/img/htshape17.png" alt="" />
            </span>
            <span className="fs-4">
              <img src="/website/images/img/htleaf.png" alt="" />
            </span>
          </div>
          <div className="container">
            <div className="common-title-area text-center padding-bottom-30 wow fadeInUp">
      
            </div>
      
          </div>
        </section>

      </div>
    </>
  );
};

export default TodayMenu;