import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const PaymentStatus = () => {
  const { t } = useTranslation();
  const location = useLocation(); // Get the current location

  // Extract query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  // Determine the message based on the status query parameter
  let message;
  switch (status) {
    case "success":
      message = t("Your fresh bite wallet top-up has been successful.");
      break;
    case "fail":
      message = t("Your fresh bite wallet top-up has failed.");
      break;
    default:
      message = ""; // Empty message if status is neither success nor fail
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main>
        <div className="payment-status">
          {status && (
            <>
              <img
                src={status === "success" ? "/assets/img/success.png" : "/assets/img/fail.png"}
                alt={status === "success" ? "Payment Success" : "Payment Failure"}
                className="status-image"
              />
              <h1 className="status-title">
                {status === "success" ? t("Payment Successful") : t("Payment Failed")}
              </h1>
              <p className="status-description">{message}</p>
            </>
          )}
        </div>
      </main>
      <style jsx>{`
        main {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }

        .payment-status {
          text-align: center;
        }

        .status-image {
          width: 150px;
          height: auto;
          margin-bottom: 20px;
        }

        .status-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .status-description {
          font-size: 18px;
          margin-bottom: 30px;
          color: #6c757d;
        }

        @media screen and (max-width: 768px) {
          .status-title {
            font-size: 20px;
          }

          .status-description {
            font-size: 16px;
          }
        }

        @media screen and (max-width: 480px) {
          .status-title {
            font-size: 18px;
          }

          .status-description {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};

export default PaymentStatus;
