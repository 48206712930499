import React, { useEffect, useContext, useState,useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  pageLoading,
  paginationLoading,
  pagination,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import Moment from "react-moment";
//importing context consumer here
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { NavLink, useParams } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from "react-select/animated";

import CloseButton from 'react-bootstrap/CloseButton';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const OpeningClosingStock = () => {
  const {
    //work period
    workPeriodList,
    setPaginatedWorkPeriod,
    // workPeriodForSearch,
    dataPaginating,
  } = useContext(RestaurantContext);

  const {
    getFoodStockReport,
    loading,
    setLoading,
    items,
    setItemsFood,
    itemsFood,
    theGroupsFood,
    foodItemStock,
    getFoodGroup
  } = useContext(FoodContext);

  const { authUserInfo } = useContext(UserContext);

  const {
    generalSettings,
  } = useContext(SettingsContext);

  let {
    branchList,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();
  const { started_at } = useParams();

  //new unit
  let [newWorkPeriod, setNewWorkPeriod] = useState({
    user_type: null,
    branch_id: null,
    uploading: false,
  });
  const [toggleStates, setToggleStates] = useState({});

  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });

  //check alreday updated
  const [isUpdated, setIsUpdated] = useState(true);
  const [branch, setBranch] = useState('');
  //print stock report
  const componentRef = useRef();

  //useEffect == componentDidMount
  useEffect(() => {
    getFoodStockReport(started_at);
    getFoodGroup();
    showPrintButton();
    if(branchList.data){
     let branch= branchList.data.find(branch => branch.id === authUserInfo.details.branch_id)
     setBranch(branch)
    }
  }, []);

  //update stock
  const handleUpdate = () => {
    setLoading(true);
    const url = BASE_URL + `/settings/update-closing-items`;
    let formData = {
      items: itemsFood,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        handlePrint();
        getFoodStockReport(started_at);
        setLoading(false);
        toast.success(`${_t(t("Closing stock has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        window.location.replace('/dashboard/work-periods')
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //stock
  const handleGetStock = (id) => {

    if (authUserInfo.details.branch_id === null) {
      return 0;
    }

    let stock = foodItemStock.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === parseInt(authUserInfo.details.branch_id)
      );
    });
    if (stock === undefined) {
      return 0;
    }
    return stock.qty;
  };

  // const handleReadOnly = (item) => {
  //   let total =
  //     parseInt(item.opening_stock) + parseInt(item.addition_to_opening);
  //   if (total === 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  //maximum
  const hadnleMax = (item) => {
    let temp =
      parseFloat(item.opening_stock) +
      parseFloat(
        item.addition_to_opening !== null ? item.addition_to_opening : 0
      ) -
      parseFloat(
        item.subtraction_from_opening !== null
          ? item.subtraction_from_opening
          : 0
      );
    return JSON.stringify(temp);
  };

  const options = [
    { value: 'inventory country', lable: 'inventory country' },
    { value: 'damage', lable: 'damage' },
    { value: 'loss', lable: 'Loss' },
  ]



  const handleDelete = (itemId) => {
    // Create a copy of the current state
    const updatedToggleStates = { ...toggleStates };

    // Delete the item with the specified itemId
    delete updatedToggleStates[itemId];

    // Update the state using setState
    setToggleStates(updatedToggleStates);
  };


  const showPrintButton = () => {
    if(workPeriodList){
      const foundItem = workPeriodList.data.find(item => item.started_at === started_at && item.ended_at === null);

      if (foundItem) {
        setIsUpdated(false);
      } else {
          setIsUpdated(true);
      }
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
    },
  });

  return (
    <>
      <Helmet>
        <title>{_t(t("Opening Closing Stock"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newWorkPeriod.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {_t(t("Add Closing Stock of Items"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5"></div>
                    <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                      <div className="row gx-2 align-items-center">
                        <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                          <ul className="t-list fk-sort align-items-center justify-content-center">
                            <li className="fk-sort__list mb-0 flex-grow-1">
                              <NavLink
                                to="/dashboard/work-periods"
                                className="w-100 btn alert-danger sm-text text-uppercase"
                              >
                                {_t(t("Go Back"))}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <form
                        className="table-responsive"
                        onSubmit={handleUpdate}
                      >
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                style={{ width: '50px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                style={{ width: '300px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >

                                {_t(t("Name"))}
                              </th>
                              <th
                                style={{ width: '100px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Opening Stock"))}
                              </th>

                              <th
                                style={{ width: '100px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("In stock"))}
                              </th>

                              <th
                                style={{ width: '200px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Stock Adjustment"))}
                              </th>
                              <th
                                style={{ width: '100px' }}
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Closing Stock"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                itemsFood &&
                                theGroupsFood && [
                                  itemsFood.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    theGroupsFood.map(
                                      (grpItem, grpItemIndex) => {
                                        return (
                                          <>
                                            <tr className="align-middle alert-success">
                                              <td
                                                scope="row"
                                                colSpan="8"
                                                className="xsm-text align-middle text-left ml-5 text-uppercase"
                                              >
                                                {grpItem.name}
                                              </td>
                                            </tr>
                                            {itemsFood.map((item, index) => {
                                              if (
                                                parseInt(
                                                  item.ingredient_group_id
                                                ) === grpItem.id
                                              ) {
                                                return (
                                                  <tr
                                                    className="align-middle"
                                                    key={index}
                                                  >
                                                    <th
                                                      scope="row"
                                                      className="xsm-text text-capitalize align-middle text-center"
                                                    >
                                                      {index + 1}
                                                    </th>

                                                    <td className="xsm-text align-middle text-center text-secondary">
                                                      {item.ingredient_name}
                                                    </td>

                                                    <td className="xsm-text align-middle text-center">
                                                      {item.opening_stock}
                                                    </td>

                                                    <td className="xsm-text align-middle text-center">
                                                      {handleGetStock(
                                                        item.ingredient_id
                                                      )}
                                                    </td>
                                                    {/* <td className="xsm-text align-middle text-center">
                                                      {item.addition_to_opening ===
                                                        null
                                                        ? 0
                                                        : item.addition_to_opening}
                                                    </td> */}

                                                    {/*------------- stock Adjustment start -----------------*/}

                                                    <td className="xsm-text align-middle text-center">

                                                      {handleGetStock(
                                                        item.ingredient_id
                                                      ) > 0 &&
                                                        <>
                                                          <div className="row">
                                                            <div className={toggleStates.hasOwnProperty(item.id) ? 'col-9' : 'col-12'} >

                                                              <div className="">
                                                                <Select
                                                                  value={toggleStates.hasOwnProperty(item.id) ? { value: toggleStates[item.id].reason, lable: toggleStates[item.id].reason } : { value: 'Select reason', lable: 'Select reason' }}
                                                                  options={options}
                                                                  components={makeAnimated()}
                                                                  getOptionLabel={(option) => option.lable}
                                                                  getOptionValue={(option) => option.value}
                                                                  className="basic-multi-select"
                                                                  classNamePrefix="select"

                                                                  onChange={(e) => {
                                                                    setToggleStates({
                                                                      ...toggleStates,
                                                                      [item.id]: { 'reason': e.value },
                                                                    })

                                                                    let tempArray =
                                                                      [];
                                                                    itemsFood.map(
                                                                      (pushThis) => {

                                                                        if (
                                                                          pushThis.id !==
                                                                          item.id
                                                                        ) {
                                                                          tempArray.push(
                                                                            pushThis
                                                                          );
                                                                        } else {
                                                                          if (
                                                                            e.value !==
                                                                            "" &&
                                                                            e.value !==
                                                                            null
                                                                          ) {
                                                                            pushThis.reason = e.value;
                                                                          } else {
                                                                            pushThis.reason = '';
                                                                          }
                                                                          tempArray.push(
                                                                            pushThis
                                                                          );
                                                                        }
                                                                      }
                                                                    );
                                                                    setItemsFood(
                                                                      tempArray
                                                                    );

                                                                  }}
                                                                  placeholder={
                                                                    _t(t("Select reason")) +
                                                                    ".."
                                                                  }
                                                                />
                                                              </div>
                                                            </div>

                                                            {(toggleStates.hasOwnProperty(item.id)) && <div className=" col-3 text-center">
                                                              <div onClick={() => {
                                                                handleDelete(item.id);
                                                              }}
                                                                style={{ background: '#e26a00', borderRadius: '5px', padding: '5px', alignItems: 'center', color: 'white' }} className="">
                                                                <h6 style={{ color: 'white', alignSelf: 'center', }}>X</h6>
                                                              </div>
                                                            </div>}
                                                          </div>
                                                          <div className="row">
                                                            <div className="col-9 ">
                                                              {toggleStates[item.id] && (
                                                                <>
                                                                  <input
                                                                    style={{ marginTop: '10px' }}
                                                                    type="number"
                                                                    className="form-control"
                                                                    name={item.id}
                                                                    placeholder={_t(
                                                                      t(
                                                                        "Count"
                                                                      )
                                                                    )}
                                                                    max={handleGetStock(
                                                                      item.ingredient_id
                                                                    )}
                                                                    min={0}
                                                                    value={
                                                                      item.subtraction_from_opening ||
                                                                      null
                                                                    }
                                                                    onChange={(e) => {
                                                                      let tempArray =
                                                                        [];
                                                                      itemsFood.map(
                                                                        (pushThis) => {

                                                                          if (
                                                                            pushThis.id !==
                                                                            item.id
                                                                          ) {
                                                                            tempArray.push(
                                                                              pushThis
                                                                            );
                                                                          } else {
                                                                            if (
                                                                              e.target
                                                                                .value !==
                                                                              "" &&
                                                                              e.target
                                                                                .value !==
                                                                              null
                                                                            ) {
                                                                              pushThis.subtraction_from_opening = parseFloat(e.target.value);
                                                                              pushThis.closing_stock = handleGetStock(item.ingredient_id) - parseFloat(e.target.value);
                                                                            } else {
                                                                              pushThis.subtraction_from_opening = null;
                                                                              pushThis.closing_stock = null;
                                                                            }
                                                                            tempArray.push(
                                                                              pushThis
                                                                            );
                                                                          }
                                                                        }
                                                                      );
                                                                      setItemsFood(
                                                                        tempArray
                                                                      );
                                                                    }}
                                                                  />

                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>}
                                                    </td>
                                                    {/*------------- stock Adjustment  end-----------------*/}

                                                    <td style={{ width: '5px' }} className=" align-middle text-center">

                                                      <input
                                                        disabled
                                                        type="number"
                                                        className="form-control"
                                                        name={item.id}
                                                        placeholder={_t(
                                                          t(
                                                            "Keep fields blank to calculate auto"
                                                          )
                                                        )}
                                                        max={hadnleMax(
                                                          item
                                                        )}
                                                        value={
                                                          item.closing_stock ||
                                                          handleGetStock(item.ingredient_id)
                                                        }
                                                        onChange={(e) => {
                                                          let tempArray =
                                                            [];
                                                          itemsFood.map(
                                                            (pushThis) => {
                                                              if (
                                                                pushThis.id !==
                                                                item.id
                                                              ) {
                                                                tempArray.push(
                                                                  pushThis
                                                                );
                                                              } else {
                                                                if (
                                                                  e.target
                                                                    .value !==
                                                                  "" &&
                                                                  e.target
                                                                    .value !==
                                                                  null
                                                                ) {
                                                                  pushThis.closing_stock =
                                                                    parseFloat(
                                                                      e
                                                                        .target
                                                                        .value
                                                                    );
                                                                } else {
                                                                  pushThis.closing_stock =
                                                                    null;
                                                                }
                                                                tempArray.push(
                                                                  pushThis
                                                                );
                                                              }
                                                            }
                                                          );
                                                          setItemsFood(
                                                            tempArray
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                );
                                              } else {
                                                return false;
                                              }
                                            })}
                                          </>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]
                              : ""}
                          </tbody>
                        </table>
                        {itemsFood && itemsFood.length > 0 && !isUpdated && (
                          <button
                            type="submit"
                            className="btn btn-primary float-right px-5 mb-3"
                          >
                            {_t(t("update"))}
                          </button>
                        )}
                      </form>
                     {isUpdated && <button
                      onClick={()=>handlePrint()}
                          className="btn btn-primary float-right px-5 mb-3"
                        >
                          {_t(t("Print"))}
                        </button>}
                        
                        

                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {newWorkPeriod.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedWorkPeriod.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              workPeriodList,
                              setPaginatedWorkPeriod
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {/* {showingData(workPeriodList)} */}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedWorkPeriod({
                                    ...searchedWorkPeriod,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {/* {searchedShowingData(
                                    searchedWorkPeriod,
                                    workPeriodForSearch
                                  )} */}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
          </div>
        </div>
      </main>

 {/*-------------------------- Print report-------------------- */}
         <div className="d-none">
        <div ref={componentRef}>
         
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="d-block mt-40 fk-print-text font-weight-bold text-uppercase text-center sm-text">
                    {getSystemSettings(generalSettings, "siteName")}
                      {","}
                      {branch.name&&branch.name}
                    </span>
                   <p className="mb-2 sm-text fk-print-text text-center text-capitalize">
                   Sent Date :<Moment format="LL">{new Date()}</Moment>                Sent Time : <Moment format="hh:mm A">{new Date()}</Moment>
                   
                    </p>
                   <p className="mb-2 sm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "type_print_heading")}
                    </p>
                    <span className="d-block fk-print-text text-uppercase text-center lg-text myBorderTopCustomer">
                    Stock Report For <Moment format="L">{new Date()}</Moment>
                    </span>
                    <p className="mb-0 mt-0 sm-text fk-print-text text-capitalize text-center">
                      {_t(t("work period end print"))}
                    </p>
                    <p className="mb-3 xsm-text fk-print-text text-capitalize">
                    POS User : {authUserInfo&&authUserInfo.details.name}
                    </p>
                   
                    <p className="mb-2 xsm-text font-weight-bold fk-print-text text-capitalize">
                    Stock Details
                    </p>
                    <div className="myBorder"></div>
                    <table className="table mb-0 table-borderless akash-table-for-print-padding">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="fk-print-text xsm-text text-capitalize"
                          >
                            Item name
                          </th>
                          <th
                            scope="col"
                            className="fk-print-text xsm-text text-capitalize text-right"
                          >
                           {_t(t("Opening"))}
                          </th>
                          <th
                            scope="col"
                            className="fk-print-text xsm-text text-capitalize text-right"
                          >
                            {_t(t("In"))}
                          </th>
                            <th
                            scope="col"
                            className="fk-print-text xsm-text text-capitalize text-right"
                          >
                            {_t(t("Westage"))}
                          </th>
                          <th
                            scope="col"
                            className="fk-print-text xsm-text text-capitalize text-right"
                          >
                            {_t(t("Closing"))}
                          </th>
                        </tr>
                      </thead>
                         <tbody>
                        {itemsFood&&itemsFood.map((printItem, printItemIndex) => {
                          return (
                            <tr>
                              <td className="fk-print-text xsm-text text-capitalize">
                                <div className="d-flex flex-wrap">
                                  <span className="d-inline-block xsm-text">
                                    -{printItem.ingredient_group_id} {printItem.ingredient_name}
                                  </span>
                                </div>

                               
                              </td>
                              <td className="fk-print-text xsm-text text-capitalize text-right">
                                <div className="d-block xsm-text">
                                {printItem.opening_stock}
                                </div>
                              </td>
                              <td className="fk-print-text xsm-text text-capitalize text-right">
                                <div className="d-block xsm-text">
                                {handleGetStock(printItem.ingredient_id)}
                                </div>
                              </td>
                             {printItem.subtraction_from_opening!==null &&<td className="fk-print-text xsm-text text-capitalize text-right">
                                <div className="d-block xsm-text">
                                  <div className="d-block xsm-text"> {printItem.subtraction_from_opening}</div>
                                  <div className="d-block xsm-text">{printItem.reason}</div>
                               
                                
                                </div>
                              </td>}
                              <td className="fk-print-text xsm-text text-capitalize text-right">
                                <div className="d-block xsm-text">
                                {printItem.closing_stock}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </>
  );
};

export default OpeningClosingStock;
