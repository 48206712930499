import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL, SAAS_APPLICATION, saas_apiUrl, saas_apiParams, saas_form_data } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ListGroup from 'react-bootstrap/ListGroup';

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { FoodContext } from "../../../../../contexts/Food";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import Expired from "../../../../../saasHelper/Expired";
import SaasApiFailure from "../../../../../saasHelper/saasApiFailure";
import * as XLSX from "xlsx";

const FoodItemsBulkUpload = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  let [apiFailed, setApiFailed] = useState(false);
  let [hasSaasLimit, setHasSaasLimit] = useState(true);
  const [data, setData] = useState([]);

  // saas integration
  useEffect(() => {
 
  
  }, []);


  //send to server
  const uploadToServer = () => {
   
    if(data.length>0){
      const url = BASE_URL + "/settings/new-bulk-upload";

      return axios
        .post(url, {food_items:data}, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setLoading(false);
          setData([])
          toast.success(`${_t(t("Food items has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          document.getElementById("uploadFileInput").value = null;
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }else{
      toast.success(`${_t(t("select excel file."))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
 
  };



const  handleFileUpload  = (e) =>{ 
    const reader = new FileReader();
   
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e)=>{
        const data = e.target.result;
        const workbook = XLSX.read(data,{type:"binary"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parseData = XLSX.utils.sheet_to_json(sheet);
       setData(parseData);
    }
};

  return (
    <>
      <Helmet>
        <title>{_t(t("Add Bulk Items"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (apiFailed ?
                      [
                        <SaasApiFailure />
                      ] : [
                        hasSaasLimit ? [
                          // start here
                          <div key="smtp-form">
                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                              <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                <ul className="t-list fk-breadcrumb">
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                      {_t(t("Bulk Items Upload  "))}
                                    </span>
                                  </li>
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link  text-capitalize">
                                      {_t(t("Upload Excel File"))}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6 col-lg-7">
                                <div className="row gx-3 align-items-center">

                                </div>
                              </div>
                            </div>

                         <div>
                            <input
                            type='file'
                            accept=".xlsx,.xls"
                            id='uploadFileInput'
                            onChange={handleFileUpload}
                            />
                           
                          {data.length >0 &&(
                            <>
                              <div className="form-group mt-5 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5"
                                  onClick={()=>uploadToServer()}
                                >
                                  {_t(t(`Upload to Menu`))}
                                </button>
                                <span> {data.length } items available</span>
                              </div>
                              
                            </div>
                            <table className="table" style={{ borderCollapse: 'collapse', width: '100%' }}>
    <thead style={{ background: '#9eb8cd', color: '#fff' }}>
        <tr>
            {Object.keys(data[0]).map((key) => (
                <th key={key} style={{ padding: '8px', border: '1px solid #ddd' }}>{key}</th>
            ))}
        </tr>
    </thead>
    <tbody>
        {data.map((row, index) => (
            <tr key={index} style={{ background: index % 2 === 0 ? '#f2f2f2' : '#fff' }}>
                {Object.values(row).map((value, index) => (
                    <td key={index} style={{ padding: '8px', border: '1px solid #ddd', fontSize:14 }}>{value}</td>
                ))}
            </tr>
        ))}
    </tbody>
</table>
                            </>
                           )}
                        
                         </div>
                          </div>
                          // end here
                        ] : [
                          <Expired />
                        ]
                      ]
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default FoodItemsBulkUpload;
